@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.text-break {
  overflow-wrap: break-word;
  word-break: break-word;
}

.white-text-shadow {
  text-shadow: 10px 1px 15px #fff;
}

.greenMirror-text-shadow {
  text-shadow: 10px 1px 15px #54f7c5;
}

.discord-button {
  font-family: Whitney, 'Open Sans', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12pt;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;

  &.sized {
    border: 2px solid #7289da;
    background-color: #7289da;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    height: 45px;
    width: 250px;
  }

  &.red {
    background-color: red;
    border-color: red;
  }
}

.scrollbar-mirror::-webkit-scrollbar {
  width: 8px;
  height: 20px;
}

.scrollbar-mirror::-webkit-scrollbar-track {
  scroll-padding: 4px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 10px;
  border-radius: 100vh;
  background: #0b1021;
}

.scrollbar-mirror::-webkit-scrollbar-thumb {
  background: #256bfb;
  border-radius: 100vh;
}

.scrollbar-mirror::-webkit-scrollbar-thumb:hover {
  background: #3b77ee;
}

.not-liked path {
  fill: none !important;
  stroke: #256bfb;
  stroke-width: 50px;
}

.clickAnimate {
  transition: all 0.5s ease-in-out;
}

.clickAnimate:active svg {
  animation: heartbeat 0.5s ease-in-out alternate-reverse;
  border: none;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.25);
  }
  75% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

.viewer {
  @apply rounded-lg overflow-hidden;
  button {
    @apply bg-none text-inherit border-none text-xl flex justify-center items-center cursor-pointer ml-2 text-white mix-blend-difference;
  }
  .picker {
    @apply absolute bottom-full right-0 transform -translate-y-4;
  }
  .fullscreen {
    @apply absolute right-3;
    bottom: 0.63rem;
    svg {
      @apply text-2xl;
    }
  }
  .color {
    @apply absolute bottom-3 right-10;
  }
}

@media (min-width: 1023.98px) and (max-width: 1068px) {
  .space-card-wrap {
    max-width: 17.25rem;
  }
}

.btn-danger {
  background: #ff6d6d !important;
}

// tooltips css//
.tooltip {
  transition: 200ms all ease-in-out;
}

.tooltip[role='tooltip-right']::after {
  content: ' ';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -12px;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #1b1e3d transparent transparent;
}

.tooltip-trigger:hover ~ .tooltip {
  opacity: 1;
  visibility: visible;
}

//shadow None important for buttons that don't require glow effect
.shadowNone {
  box-shadow: none !important;
}

.materials-cards > div {
  border-color: #af46ff;
}

// scrollbar style
::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #8795b3;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #658af6;
}
//drag and drop input
.mirror-drag-drop-input {
  border-radius: 10px;
  border: 1px dashed rgba(125, 129, 157, 0.5);
  background: rgba(0, 0, 0, 0.4);
}

.mirror-drag-drop-input:hover {
  border-color: #256bfb;
}

.mirror-drag-drop-input:hover .browse-text {
  text-decoration: underline;
}

.progress-bar-wrap {
  background: rgba(0, 0, 0, 0.4);
}

// multiselect styling
.tags-multi-select .select__control {
  background: #121428;
  border: 0;
  height: 40px;
}
.tags-multi-select .select__control.select__control--is-focused {
  box-shadow: none;
  border: 0;
}

.tags-multi-select .select__input-container input {
  color: #fff !important;
  font-weight: 500 !important;
}

.tags-multi-select .select__placeholder {
  color: #fff !important;
}

.tags-multi-select .select__input-container input:focus {
  border: 0 !important;
  box-shadow: none !important;
}

.tags-multi-select .select__multi-value {
  background: #256bfb;
  color: #fff;
  border-radius: 20px;
  margin: 3px 5px 3px 0;
}

.tags-multi-select .select__multi-value__label {
  color: #fff;
  font-weight: 600;
  padding-left: 8px;
}

.tags-multi-select .select__menu {
  background: #121428;
  border-radius: 0.375rem;
  border: 1px solid #6b7280;
  transition: all 200ms ease-in-out;
}

.tags-multi-select .select__menu .select__menu-list {
  background: #121428;
  border-radius: 0.375rem;
}

.tags-multi-select .select__menu .select__option {
  color: #fff;
  background: #121428;
}

.tags-multi-select .select__menu .select__option:hover {
  background: #256bfb;
}

.edit-asset-type > div,
.edit-asset-type > div > button {
  height: 4.25rem;
  width: 100%;
}

.edit-asset-type > div > button .text-textInput {
  font-weight: 600;
  margin-top: 2px;
}

.edit-asset-type ul {
  max-height: 6rem;
  padding-bottom: 0.5rem;
  overflow: auto;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.border-spacing-custom {
  border-spacing: 0 8px;
}

.edit-asset-wrap {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  gap: 4%;
  padding: 10px 20px;
}

.grid-view .edit-asset-wrap {
  grid-template-columns: repeat(2, 48%);
  gap: 4%;
  padding: 0 20px;
}

.grid-view .edit-asset-type > div,
.grid-view .edit-asset-type > div > button {
  width: 100%;
}

.list-header {
  display: grid;
  grid-template-columns: 5% 10% 18% repeat(4, calc(9%)) 15% 8% 8%;
  align-items: center;
}

.list-header > li {
  padding: 2px 15px;
}

.cart-table {
  display: grid;
  grid-template-columns: 10% 18% repeat(3, calc(12%)) 15% repeat(2, calc(8%));
  gap: 1%;
  align-items: center;
  overflow: hidden;
}

@media (min-width: 1690px) {
  .edit-asset-wrap {
    grid-template-columns: 15% repeat(3, 1fr);
    gap: 2%;
  }
}

@media screen and (max-width: 1920px) {
  .list-header > li:nth-child(1) {
    padding: 2px 12px;
  }
  .list-header > li {
    padding: 2px 8px;
  }
}

@media screen and (max-width: 1326px) {
  .list-header {
    grid-template-columns: repeat(5, 19%);
    gap: 1%;
  }
  .cart-table {
    grid-template-columns: repeat(4, 24%);
    gap: 2%;
  }
  .edit-asset-wrap {
    grid-template-columns: repeat(2, 50%);
    padding: 0 50px;
  }
  .edit-asset-type > div,
  .edit-asset-type > div > button {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .grid-view {
    width: 100%;
  }
  .grid-view .edit-asset-wrap {
    grid-template-columns: 1fr;
    padding: 0 12px;
    max-height: 550px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 767px) {
  .list-header,
  .cart-table {
    grid-template-columns: repeat(2, 49%);
    gap: 1%;
  }
  .edit-asset-wrap {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }
}
